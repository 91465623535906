import React from "react";
import styled from "styled-components";
import { IonIcon } from "@ionic/react";

import WhatsAppIcon from "../../assests/icons/svg/whatsapp.svg";
import GmailIcon from "../../assests/icons/svg/gmail.svg";

import Flex from "../flex";
import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import { FileSharer } from "capacitor-plugin-filesharer";
import { deviceInformation } from "../../lib/deviceInformation";

const SocialShareWrapper = styled.div`
  margin: ${({ margin }) => margin || "unset"};
`;
const ShareText = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2px;
  color: var(--text-primary);
`;

const SocialShareItem = styled(Flex)`
  background: ${({ backgroundColor }) => backgroundColor || "#ffffff"};
  box-shadow: 0px 0px 10px 5px rgba(208, 203, 203, 0.15);
  border-radius: 10px;
  height: 4.063rem;
  width: 100%;
  margin: 22px 0;
  padding: 0 12%;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  transition: 0.25s all;
  &:hover {
    box-shadow: 1px 1px 11px 2px #d1cbcb;
  }
`;
const SocialShareItemWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const Icon = styled(IonIcon)`
  font-size: 2.5rem;
  margin-right: 18px;
`;

const SocialShareList = ({
  options = [],
  selectedAssets = [],
  margin = "0px 10%",
  backgroundColor = "",
}) => {
  const testURL =
    "https://images.unsplash.com/photo-1637497520435-cb436ebddc8c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDN8TThqVmJMYlRSd3N8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60";
  const socialShareOptions =
    options?.length > 0
      ? [...options]
      : [
          { title: "WhatsApp", value: "whatsapp", icon: WhatsAppIcon },
          { title: "Gmail", value: "gmail", icon: GmailIcon },
        ];

  const getBase64FromUrl = async (url) => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  const getMultipleBase64Formatted = async () => {
    const filesList = [];
    await selectedAssets.forEach(async (eachSelected, eachSelectedIndex) => {
      if (eachSelected.url) {
        const formattedFileName = eachSelected.url?.split("/").pop() || "";
        const base64 = await getBase64FromUrl(
          `${process.env.REACT_APP_NODE_API_URL}/file/${formattedFileName}`
        );
        const formattedBase64 = base64?.split(",") || [];
        const base64Data = formattedBase64[1] || "";
        const fileType =
          formattedBase64[0]?.replace(/data:|;base64/gi, "") || "";
        filesList.push({
          filename: eachSelected.name || "",
          base64Data,
          contentType: fileType,
        });
      }
    });
    return filesList;
  };

  const onShareAssetVersion = async (value) => {
    const deviceData = await deviceInformation();
    if (deviceData?.platform === "web") {
      let linkElement = document.createElement("a");
      let formattedTextMessage = "";
      selectedAssets.forEach((eachSelected, eachSelectedIndex) => {
        formattedTextMessage = formattedTextMessage.concat(
          `${eachSelectedIndex + 1}. ${eachSelected.name}\r\n ${
            eachSelected.url
          }\r\n\r\n`
        );
      });
      if (value === "whatsapp") {
        linkElement.href = `https://web.whatsapp.com/send?text=${encodeURIComponent(
          formattedTextMessage
        )}`;
        linkElement.rel = "nofollow noopener";
        linkElement.target = "_blank";
        linkElement.click();
      } else {
        linkElement.setAttribute(
          "href",
          `mailto:?subject=Vizdom&body=${encodeURIComponent(
            formattedTextMessage
          )}`
        );
        linkElement.click();
      }
    } else {
      const filesList = await getMultipleBase64Formatted();
      if (value === "whatsapp") {
        SocialSharing.shareViaWhatsApp("Vizdom", "", filesList)
          .then(() => {
            console.log("success");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        SocialSharing.shareViaEmail("", "Vizdom", "", "", "", filesList)
          .then(() => {
            console.log("success");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const onShareMultipleApp = async () => {
    const filesList = await getMultipleBase64Formatted();
    FileSharer.shareMultiple({
      header: "Vizdom",
      files: [...filesList],
    })
      .then(() => {
        console.log("File share success");
      })
      .catch((error) => {
        console.error("File sharing failed", error.message);
      });
  };

  const onTestShare = async () => {
    const base64Data = getBase64FromUrl(testURL);
    SocialSharing.shareViaWhatsApp("Vizdom", base64Data, "")
      .then(() => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onTestShare2 = async () => {
    SocialSharing.shareViaWhatsApp("Vizdom", testURL, testURL)
      .then(() => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SocialShareWrapper margin={margin}>
      {socialShareOptions?.map((each, socialShareIndex) => (
        <SocialShareItemWrapper disabled={selectedAssets?.length <= 0}>
          <SocialShareItem
            disabled={selectedAssets?.length <= 0}
            alignCenter
            key={`share-asset-version-${socialShareIndex}`}
            onClick={() =>
              onShareAssetVersion && onShareAssetVersion(each?.value)
            }
            backgroundColor={backgroundColor}
          >
            <Icon icon={each?.icon} />
            <ShareText>{`Share using ${each?.title}`}</ShareText>
          </SocialShareItem>
        </SocialShareItemWrapper>
      ))}
      {/* TODO Need to test */}
      {/* <button onClick={onShareMultipleApp}>Share</button> */}
      {/* <div>
        <button onClick={onTestShare}>Test share</button>
      </div>
      <div>
        <button onClick={onTestShare2}>Test share 2</button>
      </div> */}
    </SocialShareWrapper>
  );
};

export default SocialShareList;
