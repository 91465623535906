import React, { useState } from "react";
import { Image as AntdImage, Badge } from "antd";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import styled from "styled-components";
import Flex from "../flex";
import { IonIcon } from "@ionic/react";
import { useHistory, Link } from "react-router-dom";
import { chevronBack } from "ionicons/icons";
import ImageMarker from "../imageMarker";
import { RightOutlined } from "@ant-design/icons";
import Dot from "components/dot";
import Arrow from "../../assests/icons/arrow";
import ArrowRight from "assests/icons/svg/arrowRight.svg";
import ArrowLeft from "assests/icons/svg/arrowLeft.svg";

import PlaceholderImage from "../../assests/images/placeholder.jpg";
import { thumbnail } from "./thumb";

const Wrapper = styled(Flex)`
  flex: 1;
  background: #000000;
  position: relative;
  @media screen and (max-width: 768px) {
    background: rgb(231, 231, 231);
  }
`;

const SubWrapper = styled.div`
  width: 100%;
  flex: 1;
`;

const CarouselWrapper = styled.div`
  height: 100%;
`;

const Carousel = styled(ResponsiveCarousel)`
  background: #000000;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: unset !important;
  .carousel-slider {
    position: unset !important;
    overflow: unset !important;
    flex: 1;
  }
  .slider-wrapper {
    overflow: unset !important;
    height: 100%;
    .slider {
      height: 100%;
    }
    .slide {
      display: flex;
      flex-direction: column;
    }
  }
  .carousel {
    &:nth-child(2) {
      width: 95% !important;
      margin: 0 auto 0.3rem auto;
    }
  }
  .control-dots {
    bottom: 11px;
    width: max-content;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    .dot {
      background: #000 !important;
      box-shadow: unset !important;
      margin: 0 3px;
      @media (max-width: 768px) {
        width: 5px;
        height: 5px;
      }
    }
  }
  .carousel-status {
    border: 1px solid #ffffff;
    backdrop-filter: blur(4px);
    background: rgba(201, 201, 201, 0.7);
    border-radius: 100px;
    width: fit-content;
    min-width: 5.849rem;
    padding: 0 10px;
    height: clamp(21px, 7vw, 35.5px);
    top: 15px;
    right: 25px;
    color: var(--text-primary);
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 0.8rem;
    text-shadow: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thumbs-wrapper {
    display: flex;
    align-items: center;
    margin: 0.45rem 1rem 0.3rem 1.375rem !important;
    min-height: 82.52px;
    z-index: 1;
    user-select: none;

    &::before {
      content: "";
      background: #f5f5f5;
      border-radius: 4px;
      height: 57px;
      position: absolute;
      width: 93%;
      z-index: 0;
      top: 21.1%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .thumb {
    width: auto !important;
    border: none !important;
    position: relative;
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    margin-right: 0px !important;
    padding: 0px !important;
  }
  .thumbs {
    margin: 0;
    padding: 0;
  }
  .control-arrow {
    overflow: hidden;
    opacity: 1;
    background: #ffffff;
    border-radius: 100px;
    height: 63px;
    width: 11px;
    top: 11% !important;
    margin: 0;
  }
  .control-next {
    &::after {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
      right: 0;
      left: 0;
      content: url(${ArrowRight});
    }
  }
  .control-prev {
    &::after {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      justify-content: center;
      align-items: center;
      right: 0;
      left: 0;
      content: url(${ArrowLeft});
    }
  }
  @media screen and (max-width: 768px) {
    background: rgb(231, 231, 231);
    .control-arrow {
      display: none;
    }
    .carousel {
      &:nth-child(2) {
        width: 100% !important;
        margin: 0 auto;
      }
    }
    .carousel-slider {
      margin-bottom: 25px;
    }
    .thumbs-wrapper {
      min-height: 55.52px;
      margin: 0.5rem 0 0.9rem 0 !important;

      &::before {
        display: none;
      }
    }
  }
`;

const IconWrapper = styled.span`
  margin-left: 15px;
`;

const ImageWrapper = styled.div`
  flex: 1;
  color: rgb(255, 255, 255);
  text-align: center;
  background-color: #e3e3e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 8 8'%3E%3Cg fill='%23cccccc' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
  overflow: hidden;
  ${({ isReviewPage }) =>
    isReviewPage
      ? `
margin-bottom: 18px;
img {
  height:calc(100vh - 18.65rem);
    
  @media screen and (max-width: 768px) {
    height: 40vh;
  }
  @media screen and (max-width: 420px) {
    height: 200.61px;

  }
}
`
      : `
      img {
        height: 410px;
        @media screen and (max-width: 600px) {
          height: 280px;
        }
        @media screen and (max-width: 420px) {
          height: 200.61px;
        }
      }

`}
`;

const Text = styled.div`
  color: ${({ status }) => (status === "approved" ? "#69b79a" : "#FF8181")};
  font-family: "Montserrat";
  font-size: 0.938rem;
  text-transform: uppercase;
  font-weight: 700;
`;

const Image = styled(AntdImage)``;
const BackButton = styled.button`
  background: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 0px;
  font-size: 14px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 0 1.5px 0 0;
`;

const NavigationButton = styled.button`
  position: absolute;
  ${({ inverse, isVisible, isReviewPage }) =>
    inverse
      ? `  left: 1rem;`
      : isVisible || !isReviewPage
      ? `  right: 1rem;`
      : "right:9rem;"}
  top: 45%;
  background: #0000008c;
  color: #fff;
  font-weight: bold;
  font-size: 0.6rem;
  z-index: 10;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  min-height: 2.3rem;
  min-width: 2.3rem;
  display: ${({ isEdit }) => (isEdit ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    ${({ inverse }) => (inverse ? `  left: 1rem;` : `  right: 1rem;`)}
    top: 47%;
  }
`;

const Div = styled.div`
  background: #f5f5f5;
  height: 59.17px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 3%;
  align-items: center;
`;

const SubTitle = styled.div`
  color: var(--text-primary);
  font-weight: ${({ fw }) => fw || 600};
  font-family: "Montserrat";
  font-size: ${({ size }) => size || "0.8rem"};
  ${({ pointer }) => pointer && "cursor:pointer"}
`;

const TruncatedText = styled(SubTitle)`
  white-space: nowrap;
  width: ${({ textWidth }) => textWidth || " 38%"};
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ color }) => color || "#ffffff"};
`;

const Chip = styled(SubTitle)`
  text-transform: capitalize;
  background: ${({ status }) =>
    status === "pending"
      ? "#ffdc3e"
      : status === "approved"
      ? "#8BDDB3"
      : "#FF6F6F"};
  border-radius: 100px;
  width: fit-content;
  min-width: 5.849rem;
  padding: 0 10px;
  height: clamp(21px, 7vw, 35.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 25px;
  @media (max-width: 768px) {
    right: 5%;
    top: 5%;
  }
`;

const Header = styled(Flex)`
  width: 100%;
  padding: 1.5rem 1.2rem 1rem 1.2rem;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ReviewImage = ({
  id = "",
  status = "pending",
  imageURL = [],
  width = "",
  approveOrRejectStatus = "",
  onApproveOrReject = undefined,
  isVisible = false,
  onImageChange = undefined,
  fileCommentList = [],
  isReview = false,
  totalFileComment = 0,
  setTotalFileComment = "",
  onPostReplyComment = undefined,
  onPostNewComment = undefined,
  isReviewPage = false,
  hideReview = false,
  deliverableName = "",
  selectedItem = 0,
  onUpdateAsset = undefined,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const currentVersion = imageURL.find(
    (each) => each?.currentVersionId === selectedItem
  );
  const [currentImage, setCurrentImage] = useState("");
  const history = useHistory();

  const getDecimalValue = (digits = 0) => {
    return Number(digits) > 0 ? digits.toString().padStart(2, "0") : 0;
  };
  const onBackButtonClick = () => {
    if (width > 768) {
      history.push("/assets");
    } else {
      if (approveOrRejectStatus && onApproveOrReject) {
        onApproveOrReject("");
      } else {
        history.push("/assets");
      }
    }
  };

  const onCarouselChange = (index, value) => {
    setIsEdit(false);
    setCurrentImage(value?.props?.id);
    if (onImageChange) onImageChange(value?.props?.id, value?.props?.name);
  };

  const goToReviewPage = () => {
    if (!isReviewPage && !hideReview && status.toLowerCase() === "pending") {
      history.push(`/assets/${id}/review`);
    }
  };

  return (
    <Wrapper column fullHeight>
      {isReviewPage && (
        <Header spaceBetween>
          <BackButton onClick={onBackButtonClick}>
            <IonIcon icon={chevronBack} color="red" />
          </BackButton>
          <Flex alignCenter flexEnd flexCount>
            <Dot
              status={currentVersion?.clientStatus?.toLowerCase()}
              size="1rem"
            />
            {deliverableName && (
              <TruncatedText
                textWidth="clamp(60px,100%,10vw)"
                fw="800"
                size="0.938rem"
              >
                {deliverableName}
              </TruncatedText>
            )}
          </Flex>
        </Header>
      )}
      <SubWrapper>
        <CarouselWrapper>
          <Carousel
            statusFormatter={(current, total) =>
              `${getDecimalValue(current)} / ${getDecimalValue(total)}`
            }
            showStatus={!isReviewPage}
            emulateTouch
            swipeable={false}
            showArrows={false}
            showThumbs={isReviewPage}
            renderThumbs={(value, option2, option3) => {
              return thumbnail({
                images: imageURL,
                selected: selectedItem,
                onCarouselChange,
              });
            }}
            selectedItem={
              (imageURL.length > 0 &&
                imageURL.findIndex(
                  (each) => each?.currentVersionId === selectedItem
                )) ||
              0
            }
            isVisible={isVisible || !isReviewPage}
            infiniteLoop
            onChange={onCarouselChange}
            showIndicators={false}
            renderArrowNext={
              !isReviewPage && imageURL.length > 1
                ? (onClick) => {
                    return (
                      <NavigationButton
                        onClick={onClick}
                        isVisible={isVisible}
                        isEdit={isEdit}
                        isReviewPage={isReviewPage}
                      >
                        <Arrow rightArrow />
                      </NavigationButton>
                    );
                  }
                : undefined
            }
            renderArrowPrev={
              !isReviewPage && imageURL.length > 1
                ? (onClick) => {
                    return (
                      <NavigationButton
                        onClick={onClick}
                        inverse
                        isEdit={isEdit}
                      >
                        <Arrow />
                      </NavigationButton>
                    );
                  }
                : undefined
            }
          >
            {(imageURL.length > 0 &&
              imageURL?.map(
                (
                  {
                    currentVersionId = "",
                    url = "",
                    name = "",
                    clientStatus = "",
                  },
                  imageIndex
                ) => (
                  <ImageWrapper
                    key={`image-carousel-${imageIndex}`}
                    isReview={isReview}
                    id={currentVersionId}
                    name={name}
                    isReviewPage={isReviewPage}
                    onDoubleClick={goToReviewPage}
                  >
                    <ImageMarker
                      fileCommentList={fileCommentList}
                      onEdit={() => setIsEdit(!isEdit)}
                      isVisible={isVisible}
                      url={url}
                      currentImage={currentImage}
                      resetEdit={() => setIsEdit(false)}
                      isReview={isReview}
                      totalFileComment={totalFileComment}
                      setTotalFileComment={setTotalFileComment}
                      isReviewPage={isReviewPage}
                      onPostReplyComment={onPostReplyComment}
                      onPostNewComment={onPostNewComment}
                      imageIndex={imageIndex}
                      currentVersionId={currentVersionId}
                      currentVersion={currentVersion}
                      onUpdateAsset={onUpdateAsset}
                      assetStatus={status}
                      imageURL={imageURL}
                      onApproveOrReject={onApproveOrReject}
                      deliverableName={deliverableName}
                    />
                    {!isReviewPage && (
                      <Chip status={clientStatus}>{clientStatus}</Chip>
                    )}
                  </ImageWrapper>
                )
              )) || (
              <ImageWrapper>
                <Image
                  width="100%"
                  preview={false}
                  src="error"
                  fallback={PlaceholderImage}
                />
              </ImageWrapper>
            )}
          </Carousel>
        </CarouselWrapper>
        {!isReviewPage && (
          <>
            <Div>
              <TruncatedText as="div" color="#000000">
                {deliverableName}
              </TruncatedText>
              {status.toLowerCase() === "pending" ? (
                !hideReview && (
                  <Link to={`/assets/${id}/review`}>
                    <SubTitle fw="bold" pointer>
                      REVIEW NOW
                      <IconWrapper>
                        <RightOutlined />
                      </IconWrapper>
                    </SubTitle>
                  </Link>
                )
              ) : (
                <Text status={status.toLowerCase()}>{status}</Text>
              )}
            </Div>
          </>
        )}
      </SubWrapper>
    </Wrapper>
  );
};

export default ReviewImage;
