import { deviceInformation } from "./deviceInformation";
import { getBase64FromUrl } from "./getBase64FromUrl";
import { Filesystem, Directory } from "@capacitor/filesystem";

export const fileDownloader = async ({ url = "", fileName = "vizdom" }) => {
  if (!url) return;
  const uniqueFileName = url?.split("/").pop() || "";
  const formattedURL = url.includes("cloudfront.net")
    ? `${process.env.REACT_APP_NODE_API_URL}/file/${uniqueFileName}?fileLocation=comments`
    : `${process.env.REACT_APP_NODE_API_URL}/file/${uniqueFileName}`;
  const deviceData = await deviceInformation();
  if (deviceData?.platform === "web") {
    fetch(formattedURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error");
        }
        return response.blob();
      })
      .then((myBlob) => {
        const blobURL = window.URL.createObjectURL(myBlob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("There has been a problem with your download", error);
      });
  } else {
    try {
      const base64Data = await getBase64FromUrl(formattedURL);
      await Filesystem.writeFile({
        path: `Vizdom`,
        data: base64Data,
        directory: Directory.Documents,
      });
    } catch (e) {
      console.error("Unable to write file", e);
    }
  }
};
